@import '../../styles/vars.scss';
@import '../../styles//scss/mixins/fluid-font-size.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
}

.title {
  color: $secondary;
  line-height: 1.1;
  font-weight: 400;
  margin: 0;
}

.descr {
  @include fluid-font-size(2.5rem, 3.75rem, 5rem);
  line-height: 1.5;
  text-transform: lowercase;
  margin: 0;

  @media (max-width: $w992) {
    text-align: center;
    line-height: 1.1;
  }
}

.help {
  display: block;
  text-align: center;
  max-width: 21.25rem;
  @include fluid-font-size(1rem, 1.125rem, 1.25rem);
  line-height: 1.5;
  margin: 0.3125rem 0 0;
}

.link {
  color: $brown;

  &:hover {
    color: $primary;
  }
}
